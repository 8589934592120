* {
  letter-spacing: inherit !important;
  outline: 0;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../src/fonts/ProximaNova-Regular.svg");
  src: url("../src/fonts/ProximaNova-Regular.ttf");
  src: url("../src/fonts/ProximaNova-Regular.eot");
  src: url("../src/fonts/ProximaNova-Regular.woff");
  src: url("../src/fonts/ProximaNova-Regular.woff2");
  font-weight: 400;
}
button:hover {
  opacity: 0.8;
}
.wrapper-box + div {
  z-index: 99999999 !important;
}
body .MuiPickersPopper-root .MuiButtonBase-root.Mui-disabled {
  color: rgba(101, 98, 98, 0.87) !important;
}
.bonus_img {
  width: 43px;
  height: 43px;
}
/* .coin_img {
  width: 31.5px;
  height: 31.5px;
} */
/* .coin_img img {
  height: 100% !important;
  width: 100% !important;
  left: 7px !important;
} */
.bonus_img img,
.card-sport-icon {
  height: 100%;
  width: 100%;
}
.select__menu * {
  cursor: pointer;
  font-size: 16px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.wallet-menu .MuiMenu-list {
  padding: 0 !important;
  background: #2b2b38 !important;
  color: unset !important;
}
.wallet-menu .MuiMenu-list ul {
  position: relative;
  background: #2b2b38 !important;
  padding: 0 !important;
}
.coin-content-item {
  border-radius: 12px;
  background: #24242b;
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.02) inset;
  padding: 10px 16px;
}
.load {
  width: 50px;
  height: 50px;
  margin: 30px auto 20px;
  border: 4px solid #5d69cf;
  border-radius: 50%;
  border-right-color: #ffffff00;
  border-bottom-color: #5d69cf;
  -webkit-transition: 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.processing-loader {
  width: 20px;
  height: 20px;
  border: 2px solid #757786;
  border-radius: 50%;
  border-right-color: #ffffff00;
  border-bottom-color: #757786;
  -webkit-transition: 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}
.App-link {
  color: #61dafb;
}
#Wallet-menu {
  left: -80px;
  backdrop-filter: blur(2px);
}
.TransactionExtoPopup,
.comment-width {
  max-width: 440px;
  min-width: 440px;
}
#Wallet-menu .MuiPaper-elevation {
  background-color: transparent !important;
  background-image: none !important;
  width: 340px;
  top: 70px !important;
  position: relative;
  overflow: visible;
}
#account-menu .MuiPaper-elevation,
#account-menu-notify .MuiPaper-elevation,
#coin-menu .MuiPaper-elevation {
  background-color: transparent !important;
  background-image: none !important;
  top: 100px !important;
  box-shadow: none;
  overflow: visible;
}
#Wallet-menu .MuiInputBase-input {
  padding-left: 20px;
}
#account-menu .MuiPaper-elevation {
  width: 340px;
  height: 340px;
  max-height: 340px;
  right: 0 !important;
  border-radius: 12px;
  position: relative;
  top: 53px;
}
.coin-border {
  border-bottom: 1px solid #2f3038;
}
#coin-menu .MuiPaper-elevation {
  width: 340px;
  max-height: 340px;
  border-radius: 12px;
  position: absolute;
  left: unset !important;
  right: 350px;
}
.userimg {
  border: 2px solid !important;
}
#account-menu-notify .MuiPaper-elevation {
  width: 440px;
  height: 100vh;
  max-height: 100vh;
  left: unset !important;
  right: 20px !important;
  border-radius: 12px;
  position: absolute;
  transform-origin: top !important;
}
#account-menu .MuiPaper-elevation:before {
  position: absolute;
  content: "url(../public/images/top-icon.svg)";
  width: 34px;
  height: 34px;
}
.MuiPaper-elevation {
  border-radius: 12px !important;
}
.MuiPaper-elevation .depositMenuList {
  display: flex !important;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  padding: 8px 15px !important;
  background: #2a2a2f !important;
}
.depositMenuListItem {
  display: flex !important;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  text-transform: capitalize;
}
.MuiPaper-elevation > ul {
  padding: 0;
  background: #2a2a2f !important;
}
.MuiPaper-elevation > ul li {
  font-size: 14px;
  color: #fff;
}
.chatboxSelectContent .MuiPaper-elevation .MuiMenu-list .MuiButtonBase-root {
  align-items: flex-start !important;
}
.MuiPaper-elevation > ul .Mui-selected:hover {
  background-color: rgb(36 36 43) !important;
}
.MuiList-root li {
  display: flex;
  padding: 2px 10px;
}
input[type="date" i]::-webkit-calendar-picker-indicator {
  filter: invert(1) !important;
}

.css-1jzi3sl-MuiGrid-root > .MuiGrid-item {
  padding-top: 10px !important;
}
.css-1jzi3sl-MuiGrid-root {
  margin-top: 0 !important;
}
.match-card-border,
.profileImgHover {
  position: relative;
}
.match-card-border:before {
  position: absolute;
  left: -30px;
  top: 0;
  width: 3px;
  height: 100px;
  content: "";
  background: #21f522;
}

.btn {
  padding: 6px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.btn-primary {
  background: #8969d8;
  border: 1px solid #8969d8;
  color: #fff;
}
.btn-primary:hover {
  background: #8b5ef9;
  border-color: #8b5ef9;
}
.carousel .slide .legend button,
.custom-checkbox .checkmark,
.custom-checkbox .container input:checked ~ .checkmark,
.usdView input:checked + .slider:before {
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0,
    #342a6f 100%
  );
}
.carousel-status,
.carousel.carousel-slider .control-arrow,
.control-dots,
.exit-btn-none,
.home-icon,
.jackpotcta img:nth-child(2),
.jackpotcta:hover img:first-child,
.orientation-mobile,
.thumbs-wrapper.axis-vertical,
.userProfileHover input {
  display: none;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(2)
  span,
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(2)
  span,
.custom-checkbox .container input:checked ~ .checkmark:after,
.jackpotcta:hover img:nth-child(2),
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(2)
  span {
  display: block;
}
.MuiButtonBase-root {
  padding: 8px 20px;
}
.chatboxSelect .MuiButtonBase-root {
  display: flex;
  justify-content: flex-start !important;
}
.TransactionExtoPopup .exit-btn2,
.formGroupMb,
.mb-0,
.spinner_btn span {
  margin-bottom: 0 !important;
}
.TransactionExtoPopup .exit-margin-bt {
  /* margin-top: 12px !important; */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.payments-scroll::-webkit-scrollbar {
  width: 2px !important;
}

.payments-scroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.payments-scroll::-webkit-scrollbar-thumb {
  background: transparent;
}

.payments-scroll:hover::-webkit-scrollbar-thumb {
  background: #32323d !important;
}

.payments-scroll:hover::-webkit-scrollbar-track {
  background: #17171b !important;
}

/* Handle on hover */
.payments-scroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  -webkit-overflow-scrolling: touch;
}
.custom-checkbox {
  margin-top: 20px;
}
.leftPannel > .MuiGrid-root::-webkit-scrollbar {
  width: 0;
}
.container {
  display: block;
  position: relative;
  padding-left: 36px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.withdraw-checkbox {
  margin-top: 10px;
}
.withdraw-checkbox .container {
  margin-bottom: 0;
}
.custom-checkbox .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1.4px solid rgba(106, 113, 199, 0.84);
  box-shadow: 0 4px 12px 2px rgba(108, 93, 193, 0.24);
  border-radius: 6px;
}
.custom-checkbox .container input:checked ~ .checkmark {
  border: 1.4px solid rgba(106, 113, 199, 0.84);
  box-shadow: 0 4px 12px 2px rgba(108, 93, 193, 0.24);
  border-radius: 5px;
}
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-checkbox .container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-checkbox label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #757786;
}
.usdView span,
.withdraw-checkbox label {
  font-size: 14px;
}
.carousel .slide,
.carousel .slide .legend {
  text-align: inherit;
}
.carousel .slide .legend {
  background-color: transparent;
  margin-left: 0;
  left: 52px;
  top: 78px;
  opacity: 1;
  bottom: inherit;
}
.chatSupportBox img {
  width: 35px;
  height: 35px;
}
.withdrawPopup {
  margin-top: 0px !important;
}
.carousel .slide .legend h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 34px;
  color: #fff;
  margin: 0;
}
.carousel .slide .legend button,
.carousel .slide .legend p {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  font-style: normal;
}
.carousel .slide .legend p {
  line-height: 140%;
  margin-top: 25px;
  margin-bottom: 40px;
  max-width: 450px;
}
.carousel .slide .legend button {
  border: 2px solid rgba(106, 113, 199, 0.84);
  box-shadow: 0 4px 12px 2px rgba(108, 93, 193, 0.24);
  border-radius: 12px;
  line-height: 29px;
  text-transform: capitalize;
  min-width: 260px;
  padding: 12px;
  cursor: pointer;
}
.carousel.carousel-slider .control-arrow:hover {
  background: 0 0;
}
.slider-wrapper {
  margin-top: 50px;
}
img[src="/images/upload-icon.svg"] {
  height: 44px !important;
  width: 44px !important;
  object-fit: inherit;
}
.MuiPickersCalendarHeader-labelContainer {
  overflow: visible;
  font-size: 15px;
  text-align: center;
}
.rightPannel {
  width: 340px;
  margin-right: 5px;
}
.rightPanelWidthNone {
  width: unset !important;
}
input:focus-visible {
  outline: 0;
}
.MuiInputBase-input:-webkit-autofill {
  -webkit-text-fill-color: #4d4d5b !important;
  -webkit-box-shadow: 0 0 0 100px #1d1d23 inset !important;
}
.usdView .slider,
.usdView input:checked + .slider,
.usdView input:focus + .slider {
  background: #1d1d23;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.12);
}
input:-internal-autofill-selected {
  background-color: #1e1e1e !important;
  border: none !important;
  border-radius: 0px !important;
}
.usdView {
  background: #2b2b38;
  padding: 10px 20px;
  border-radius: 0 0 12px 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.usdView span {
  color: #757786;
  font-weight: 500;
}
.usdView .switch {
  position: relative;
  width: 32px;
  height: 12px;
}
.usdView .switch input {
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 99;
  display: flex;
  position: relative;
  cursor: pointer;
}
.usdView .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 12px;
  width: 32px;
}
.usdView .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  bottom: -2px;
  background: #6d6f8a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.usdView input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  box-shadow: 0 2px 12px 2px rgba(108, 93, 193, 0.24);
}
.usdView .slider.round {
  border-radius: 34px;
}
.usdView .slider.round:before {
  border-radius: 50%;
}
.react-multiple-carousel__arrow {
  background: rgba(43, 43, 56, 0.44);
  border: 1.4px solid rgba(255, 255, 255, 0.12);
  box-shadow: inset 0 2px 12px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(2px);
  min-height: 20px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
}
.react-multiple-carousel__arrow::before {
  font-size: 12px;
}
.react-multiple-carousel__arrow--right {
  right: 2px;
  z-index: 1;
  top: 50%;
  min-height: 35px;
  min-width: 35px;
}
.react-multiple-carousel__arrow--left {
  left: 2px;
  z-index: 1;
  top: 50%;
  min-height: 35px;
  min-width: 35px;
}
.carousel-with-spacing .react-multi-carousel-item {
  padding: 0;
  margin-top: 40px;
}
.react-multi-carousel-item {
  padding-right: 20px;
}
aside.EmojiPickerReact.epr-main {
  height: 381px !important;
  width: 100% !important;
  border-color: rgb(46 46 53) !important;
}
aside.EmojiPickerReact.epr-main * {
  font-family: Jost !important;
}
.EmojiPickerReact .epr-header .epr-header-overlay {
  padding-bottom: 0;
}
.EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
  color: #757786;
  font-weight: 500;
  background: #2b2b38 !important;
}
.userProfileBorderProfile {
  max-width: 50px !important;
  max-height: 50px !important;
}
.userProfileEmail {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 165px;
}
.cryptoLogo-2 .react-multi-carousel-list {
  height: 100%;
}
.select__control {
  max-width: 120px;
  min-width: 120px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12) inset;
  border-radius: 10px;
  height: 44px;
  cursor: pointer;
  border: 1.4px solid rgba(255, 255, 255, 0.04) !important;
}
.close-cross,
.exit-text-danger {
  box-shadow: 0 2px 12px 0 #ffffff05 inset;
}
.select__dropdown-indicator {
  padding-right: 15px;
}
.MuiTouchRipple-root,
.select__indicator-separator {
  display: none !important;
}
.select__dropdown-indicator svg {
  color: #7c8497 !important;
}
.allGameList .react-horizontal-scrolling-menu--inner-wrapper {
  margin-top: 40px;
}
.walletModalDialog .MuiPaper-root {
  justify-content: center;
}
.css-1n5n4le-MuiCircularProgress-root {
  color: #5d69cf !important;
}
.select__input-container {
  color: #757786;
}
.languageButton .select__control {
  margin-bottom: 24px;
  min-width: 100%;
  max-width: 100%;
  border-radius: 8px;
  background: #202026;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12) inset;
  height: 40px;
}
.languageButton .select__value-container {
  padding: 2px 16px;
}
.languageButton .select__single-value {
  margin-left: 0;
  color: #fff;
}
.tabWrapContent .languageButton .select__control {
  margin-bottom: 0;
  background: #1d1d23;
  height: 32px;
  border-radius: 8px;
}
.tabWrapContent .languageButton .select__value-container {
  padding-top: 0;
  padding-left: 13px;
  padding-bottom: 0;
}
.tabWrapContent .languageButton .select__placeholder {
  color: #7f7f7f;
  font-size: 14px !important;
}
.custom-circular-progress {
  background-image: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0,
    #342a6f 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.legendContent p {
  width: 154px !important;
  padding: 12px 0 20px !important;
}
.home-mob-slider .react-multi-carousel-item {
  padding-right: 10px;
  padding-left: 10px;
}
.select__single-value {
  text-overflow: inherit;
}
.supportChat-input {
  width: 100%;
}
.MuiOutlinedInput-notchedOutline {
  border: none;
}
.bonusSectionContent .react-multi-carousel-item {
  padding: 0 10px;
}
.languageButton .select__dropdown-indicator svg {
  right: inherit;
}
.allgameListSlider .react-horizontal-scrolling-menu--item {
  margin: 0 !important;
}
.allgameListSlider .react-horizontal-scrolling-menu--scroll-container {
  gap: 9px;
}
.kyc-form .MuiInputBase-input,
.kyc-form .MuiInputBase-input:focus,
.kyc-form .MuiInputBase-input:hover {
  color: #fff !important;
  height: 38px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
}
.kyc-form .MuiInputBase-input::placeholder {
  color: #fff !important;
}
.exit-para {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.exit-para p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  text-align: center;
  /* width: 180px !important; */
}
.exit-para p:nth-child(2) {
  font-size: 16px !important;
  font-weight: 600 !important;
  max-width: 50%;
}
.exit-btn-style {
  border: 1.4px solid var(--Stroke-primary-btn, #6a71c7d6) !important;
  padding: 8px 41px !important;
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0,
    #342a6f 100%
  ) !important;
}
.exit-btn1 {
  margin-top: 30px !important;
  margin-bottom: 12px !important;

  color: #ffffff !important;
  width: 100%;
  border: 1.4px solid rgba(106, 113, 199, 0.84);
  font-size: 16px;
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0%,
    #342a6f 100%
  );
  box-shadow: 0px 4px 12px 2px rgba(108, 93, 193, 0.24);
  font-weight: 500;
  line-height: 26px;
  border-radius: 10px !important;
  text-transform: none !important;
}

.exit-btn1:hover {
  background-color: rgba(123, 98, 218, 0.08);
}
.exit-btn2 {
  margin: 12px 0 !important;
}
.exit-margin-bt {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.exit-margin-new {
  margin-top: 20px !important;
  margin-bottom: 0 !important;
}

.exit-para-bottom p {
  text-align: center;
  justify-content: center !important;
  margin: 0;
  margin-top: 16px !important;
  margin-bottom: -16px !important;
}
.exit-btn-disable {
  opacity: 0.5;
}
.exit-text-btn {
  font-size: 16px !important;
  height: 50px;
}
.exit-text-danger {
  color: #ea565b !important;
}
.popup-exit-txt {
  font-size: 12px;
  font-weight: 400;
  color: #aaa;
  margin-top: 14px !important;
}
.exit-para-sec {
  color: #00daea !important;
  padding-left: 4px;
}
.exit-para-head {
  color: var(--Black-200, #757786);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
.choose-game-red-btn,
.filterDefault button,
.transactionSelectBox .select__placeholder {
  color: #fff !important;
}
.close-cross {
  top: 16px !important;
  right: 16px !important;
  height: 40px;
  width: 40px;
  background-color: #2b2b38;
  border: 2px solid #37393d;
  border-radius: 8px;
}
.second-popup {
  width: 100%;
  min-width: 440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 12px !important;
  background-color: #24242b;
  position: relative;
}
.second-popup .card-img {
  border-top: 5px solid #2f3446;
  border-radius: 12px;
  width: 100%;
  max-width: 160px;
  height: 100%;
}
.second-popup .card-img img {
  width: 100%;
  min-height: 200px;
  object-fit: fill;
  border-radius: 12px !important;
}
.p-0 {
  padding: 0 0 0 29px !important;
}
.choose-game-btn,
.choose-game-red-btn {
  padding: 10px 20px !important;
  font-size: 16px !important;
}
.cross-absolute {
  position: absolute;
  right: 0 !important;
  border: none;
  background: 0 0;
  top: 0 !important;
}
.sec-exit-para {
  text-align: left !important;
}
.sec-exit-para p:first-child {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  line-height: 26px;
  width: 90%;
}
.sec-exit-para p:nth-child(2) {
  width: 75%;
  margin: 10px 0 0;
  line-height: 23px;
  font-size: 16px;
  font-weight: 400;
}
.backBtn p,
.forgot-terms .custom-checkbox {
  margin: 0;
}
.choose-game-btn {
  margin-top: 18px !important;
  margin-bottom: 12px !important;
  font-weight: 500 !important;
}
.choose-game-red-btn {
  font-weight: 500 !important;
  border: 1.4px solid #6a71c7d6 !important;
  border-radius: 8px !important;
  margin: 0 !important;
}
.marginToplobby {
  margin-top: 120px;
}
.filterDefault {
  border-radius: 12px !important;
  border: 2px solid rgba(255, 255, 255, 0.04) !important;
  background: linear-gradient(106deg, #34343f -60.08%, #2e2e37 100%);
  box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.02) inset;
}
.tab-space {
  padding-left: 6px;
}
.cashAppBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cashAppBtn .successButton {
  max-width: 48%;
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: none;
}
.userProfileHover {
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  display: flex;
  opacity: 0;
  position: absolute;
  background: rgba(38, 38, 48, 0.64);
  border-top: 2px solid #2f3446;
  text-align: center;
  transition: 0.5s;
  align-items: center;
  line-height: 0;
  flex-direction: column;
  backdrop-filter: blur(2px);
  justify-content: center;
}
.profileImgHover:hover .userProfileHover {
  opacity: 1;
  cursor: pointer;
}
.userProfileHover p {
  margin: 0;
  font-size: 10px;
}
.backBtn p,
.transactionHeading {
  font-size: 14px;
  font-weight: 500;
}
.forgot-terms .container {
  margin-bottom: 40px;
}
.MuiModal-root,
.MuiPickersPopper-root {
  z-index: 9999 !important;
}
#zoom_container {
  overflow: hidden;
  position: relative;
}
#zoomed_image {
  transition: transform 0.3s ease-in-out;
}
.returnButton {
  max-width: 100% !important;
}
.commentHeading {
  margin-bottom: "0px !important";
}
.btn-comment {
  margin-top: 10px !important;
}
.textFeildAreaPd > div {
  padding: 0 !important;
}
.transactionPageBannerSection {
  position: relative;
  background-image: url("../public/images/history-page-bg.jpg");
  background-repeat: no-repeat;
  background-position: center -226px;
  color: #fff;
  overflow: hidden;
}
.transaction-scroller::-webkit-scrollbar {
  display: block !important;
  width: 4px;
  height: 4px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.transaction-scroller::-webkit-scrollbar-track {
  background: #1d1d23;
}
.transaction-scroller::-webkit-scrollbar-thumb {
  background: #2b2b38;
}
.transaction-scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.transactionPageBannerSection .contentSec {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 95px;
  position: relative;
}
.transactionPageBannerSection .contentSec .backBtnDiv {
  position: absolute;
  left: 10px;
  top: 10px;
  display: none;
}
.backBtn {
  padding: 4px 8px;
  background-color: #2b2b38;
  border: 1.4px solid rgba(255, 255, 255, 0.04);
  max-width: 65px;
  max-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  color: #fff;
  gap: 6px;
}
.transactionPageBannerSection h1 {
  font-size: 40px;
  font-weight: 800;
  line-height: 32px;
  color: #fff;
}

.custom-padding {
  padding: 0;
  white-space: wrap;
}
.transactionThemeCard {
  margin: 0 0 50px;
  position: relative;
  background: #24242b;
  box-shadow: inset 0 2px 12px rgba(255, 255, 255, 0.02);
  border-radius: 20px;
}
.uploadImages img {
  position: relative;
  border-radius: 8px;
}
.uploadImages + input + .chatCrossIconUpload {
  top: -5px;
  left: 20px;
  z-index: 2;
}
.uploadImages + input + .chatCrossIconUpload img {
  width: 12px;
  height: 12px;
}
.depositMenuListItem img {
  width: 2.5vh;
}
.phone_inp1 {
  width: 12% !important;
  margin-right: 2% !important;
}
.phone_inp2 {
  width: 86% !important;
}
.phone_inp1 input::-webkit-outer-spin-button,
.phone_inp2 input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.transDivFlex {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 20px;
}
.transactionSelectBox .select__control {
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
  background-color: #1d1d23 !important;
  height: 50px;
}
.tabTransitionCustom .MuiTabs-scroller {
  background-color: #1d1d23 !important;
  border-radius: 10px !important;
  height: 100%;
}
.transactionHeading {
  color: #757786;
  line-height: 20px;
  margin-bottom: 5px;
  margin-top: 0;
}
.transactionSelectBox {
  width: 100% !important;
  max-width: 440px !important;
}
.comment-section {
  max-width: 400px;
  min-height: 300px;
  height: 300px;
  width: 100%;
  padding: 8px 10px;
  background: #1d1d23;
  border-radius: 10px;
  overflow: auto;
  margin-top: 10px;
}
.newMArgin p {
  margin-top: 30px;
}
.comment-section p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  margin: 0;
}
.pop-up-img {
  height: 80px;
  width: 80px;
  display: block;
  margin: 0px auto;
  margin-top: 40px;
}

.pop-up-new-styling {
  border-radius: 20px !important;
  background-color: #24242b;
  padding: 20px;
}
.exit-para img {
}
.exit-para {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.exitParaNew p:nth-child(2) {
  color: #757786;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
  margin-top: 30px !important;
}

.exitParaNew .exit-para-sub {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px;
}
.exit-para-sub {
  max-width: 298px;
  width: 100% !important;
}
.btn_Cancel {
  color: #00daea !important;
  cursor: pointer;
}
.mobileTableMainWrap {
  display: block;
  display: flex;
}
.theadMainWrap .transaction-head {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 24.33px;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}
.theadMainWrap .transaction-head .transaction-head-column {
  padding: 12px 10px 12px 8px;
}
.theadMainWrap .transaction-head span {
  font-size: 14px;
  font-weight: 500;
  color: #757786;
  line-height: 20px;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:first-child,
.betTableMainWrap .transaction-head .transaction-head-column:first-child,
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:first-child,
.bonusHistoryTableWrap .transaction-head .transaction-head-column:first-child,
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:first-child,
.theadMainWrap .transaction-head .transaction-head-column:first-child {
  min-width: 36px;
}
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(2),
.bonusHistoryTableWrap .transaction-head .transaction-head-column:nth-child(2),
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(2),
.theadMainWrap .transaction-head .transaction-head-column:nth-child(2) {
  min-width: 108px;
}
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(3),
.theadMainWrap .transaction-head .transaction-head-column:nth-child(3) {
  min-width: 105px;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(8),
.betTableMainWrap .transaction-head .transaction-head-column:nth-child(8),
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(4),
.theadMainWrap .transaction-head .transaction-head-column:nth-child(4) {
  min-width: 123px;
}
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(5),
.theadMainWrap .transaction-head .transaction-head-column:nth-child(5) {
  min-width: 94px;
}
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(6),
.theadMainWrap .transaction-head .transaction-head-column:nth-child(6) {
  min-width: 77px;
}
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(7),
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(8),
.bonusHistoryTableWrap .transaction-head .transaction-head-column:nth-child(7),
.bonusHistoryTableWrap .transaction-head .transaction-head-column:nth-child(8),
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(7),
.theadMainWrap .transaction-head .transaction-head-column:nth-child(7) {
  min-width: 113px;
}
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(8),
.theadMainWrap .transaction-head .transaction-head-column:nth-child(8) {
  min-width: 103px;
}
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(9),
.theadMainWrap .transaction-head .transaction-head-column:nth-child(9) {
  min-width: 96px;
}
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(10),
.theadMainWrap .transaction-head .transaction-head-column:nth-child(10) {
  min-width: 101px;
}
.theadMainWrap .transaction-body .transaction-body-row {
  display: flex;
  align-items: center;
  gap: 24.33px;
  padding-left: 20px;
  padding-right: 20px;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(4)
  span,
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(5)
  span,
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(7)
  span,
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(8)
  span,
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(4)
  span,
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(5)
  span,
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(7)
  span,
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(8)
  span,
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(4)
  span,
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(5)
  span,
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(7)
  span,
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(8)
  span {
  display: flex;
  align-items: center;
}
.theadMainWrap .transaction-body span {
  font-size: 14px;
  font-weight: 500;
  color: #757786;
  line-height: 20px;
  display: flex;
}
.theadMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column {
  padding: 10px 8px;
}
.betTableMainWrap .transaction-body:nth-child(2n),
.bonusHistoryTableWrap .transaction-body:nth-child(2n),
.theadMainWrap .transaction-body:nth-child(2n) {
  background-color: #282831;
}
.betFlexDiv span {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  line-height: 15px;
  overflow: hidden;
  max-width: 82px;
}
.betTableMainWrap .transaction-head {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 27.56px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
}
.betTableMainWrap .transaction-head .transaction-head-column,
.bonusHistoryTableWrap .transaction-head .transaction-head-column {
  padding: 12px 10px;
}
.betTableMainWrap .transaction-head span,
.bonusHistoryTableWrap .transaction-head span {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #757786;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(2),
.betTableMainWrap .transaction-head .transaction-head-column:nth-child(2) {
  min-width: 116px;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(3),
.betTableMainWrap .transaction-head .transaction-head-column:nth-child(3) {
  min-width: 140px;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(4),
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(7),
.betTableMainWrap .transaction-head .transaction-head-column:nth-child(4),
.betTableMainWrap .transaction-head .transaction-head-column:nth-child(7) {
  min-width: 110px;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(5),
.betTableMainWrap .transaction-head .transaction-head-column:nth-child(5),
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(4),
.bonusHistoryTableWrap .transaction-head .transaction-head-column:nth-child(4) {
  min-width: 80px;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(6),
.betTableMainWrap .transaction-head .transaction-head-column:nth-child(6) {
  min-width: 110px;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(9),
.betTableMainWrap .transaction-head .transaction-head-column:nth-child(9) {
  min-width: 125px;
}
.betTableMainWrap .transaction-body .transaction-body-row {
  display: flex;
  align-items: center;
  gap: 27.56px;
  padding-left: 20px;
  padding-right: 20px;
}
.betTableMainWrap .transaction-body span {
  font-size: 14px;
  font-weight: 500;
  color: #757786;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.betTableMainWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column {
  padding: 10px;
}
.bonusHistoryTableWrap .transaction-head {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 62.86px;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(3),
.bonusHistoryTableWrap .transaction-head .transaction-head-column:nth-child(3) {
  min-width: 107px;
}
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(5),
.bonusHistoryTableWrap .transaction-head .transaction-head-column:nth-child(5) {
  min-width: 97px;
}
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column:nth-child(6),
.bonusHistoryTableWrap .transaction-head .transaction-head-column:nth-child(6) {
  min-width: 86px;
}
.bonusHistoryTableWrap .transaction-body .transaction-body-row {
  display: flex;
  align-items: center;
  gap: 62.86px;
  padding-left: 20px;
}
.bonusHistoryTableWrap .transaction-body span {
  font-size: 14px;
  color: #757786;
  font-weight: 500;
  display: flex;
}
.bonusHistoryTableWrap
  .transaction-body
  .transaction-body-row
  .transaction-body-column {
  padding: 9px 10px;
}

/* buyCoins css start */

.tabHeadingNew button {
  min-height: 50px !important;
  /* border: 1.4px solid #FFFFFF0A !important; */
  box-shadow: 0px 2px 12px 0px #ffffff05 inset !important;
  border-radius: 10px !important;
  /* background: var(--Black-500, #2B2B38) !important; */
}

.buynowCardWrap span {
  font-size: 16px !important;
  line-height: 24px;
  margin-bottom: 15px !important;
}

.tabWrapContent .card-container {
  background: transparent;
  border-radius: 14px;
  padding: 20px 10px 10px 10px;
  width: 195px;
  text-align: center;
  position: relative;
  color: white;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border: 1.5px solid; */
  background: linear-gradient(
      147deg,
      #2b2b38 -19.09%,
      rgba(43, 43, 56, 0) 100.19%
    ),
    var(--Black-800, #1d1d23);

  border: 1.5px solid rgba(201, 225, 254, 0.09);
}

.card-container.active {
  /* background: linear-gradient(135deg, #6e00e8, #9c00ff); */
  outline: 2px solid var(--Stroke-primary-btn, rgba(106, 113, 199, 0.84)) !important;
  overflow: hidden;
}

/* .card-container.active .price-button {
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0%,
    #342a6f 100%
  );
  outline: 1.4px solid var(--Stroke-primary-btn, #6a71c7d6);
  border: none;
} */

/* .card-container.active .card-body p {
  color: #b0b2c2;
} */

/* .card-container.active::before {
  background-image: url(../public/images/Group194040.png) !important;
  content: "";
  background-repeat: no-repeat !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  display: block !important;
  right: 1px;
  background-position: left;
  z-index: 9999;
}

.card-container.active::after {
  background-image: url(../public/images/Group-194039.png) !important;
  content: "";
  background-repeat: no-repeat !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  left: 99px !important;
  z-index: 9999;
  top: 0;
} */

.tabWrapContent .card-container::before {
  background-image: url(../public/images/Ellipse-1789.png);
  content: "";
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  display: block;
  top: 0px;
  z-index: 9999;
}

.tabWrapContent .card-container::after {
  background-image: url(../public/images/Ellipse-1790.png);
  content: "";
  background-repeat: no-repeat;
  height: 60px;
  width: 100%;
  position: absolute;
  display: block;
  bottom: 0px;
  right: 0;
  z-index: 9999;
}

.tabWrapContent .card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.tabWrapContent .card-header {
  display: flex;
  justify-content: center;
}

.tabWrapContent .icon {
  width: 60px;
  height: 50px;
}

/* .tabWrapContent .icon-bonus {
  width: 30px;
  margin-left: 5px;
} */

.tabWrapContent .card-body h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  margin: 0;
  color: #fff;
}

.tabWrapContent .card-body p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #757786;
  margin: 0px 0 10px;
}

.tabWrapContent .card-footer {
  margin-top: auto;
}

.tabWrapContent .price-button {
  /* background: #141523; */
  color: #fff;
  padding: 8px 7px 8px 7px;
  /* outline: 1px solid var(--Purple, #6a71c7); */
  border: none;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  outline: 0.721px solid var(--Purple, #6a71c7);
  background: rgba(43, 43, 56, 0.1);
  box-shadow: 0px 1.442px 8.655px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(7.212124824523926px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.tabWrapContent .old-price {
  text-decoration: line-through;
  color: #757786;
  font-size: 15px !important;
  margin-bottom: 0px !important;
  font-weight: 600;
}

.tabWrapContent .price-button:hover {
  background-color: #24253a;
  border-color: #5b5b8a;
}

.buyNowBtnWrap button {
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0%,
    #342a6f 100%
  );
  border: 1.4px solid var(--Stroke-primary-btn, #6a71c7d6);
  box-shadow: 0px 4px 12px 2px #6c5dc13d;
  padding: 14px 41px 14px 41px;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
  font-family: Jost !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buyNowBtnWrap button span {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
  font-family: Jost !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
  margin-right: 5px;
}
.buyNowAccoutMainWrap {
  padding: 25px 20px 20px !important;
  box-shadow: 0px 2px 4px 0px #ffffff05 inset !important;
  border-radius: 20px !important;
  outline: 1px solid var(--stroke-4, #ffffff0a) !important;
  background: #202027 !important;
}

.buyNowAccoutMainWrap .buyNowCloseIcon {
  width: 40px;
  height: 40px;
  gap: 10px;
  top: -5px !important;
  border-radius: 10px;
  border: 1.4px solid var(--stroke-4, #ffffff0a);
  box-shadow: 0px 2px 12px 0px #ffffff05 inset;
  background: var(--Black-500, #2b2b38);
}

.buyNowCloseIcon p {
  margin-bottom: 0px !important;
}
.buyTabWrap {
  margin-top: 25px;
}
.tabClose .WalletHeading {
  font-size: 20px !important;
  margin-bottom: 0px !important;
  line-height: 30px !important;
}
.tabClose .flexDiv {
  display: flex !important;
  align-items: center !important;
}
.select-payment-methodWrap {
  margin-top: 15px;
}
.paymentCardWrap img {
  height: 30px;
  width: 30px;
  box-shadow: 0px 0px 15px 0px rgb(255 165 0 / 59%);
  border-radius: 50%;
}
.paymentCardWrap .cashappimg {
  box-shadow: 0px 0px 15px 0px rgba(13, 255, 0, 0.59);
  border-radius: 8px;
}
.paymentCardWrap .paymobileImg {
  box-shadow: 0px 0px 15px 0px rgba(238, 255, 0, 0.59);
  border-radius: 6px;
}
.paymentCardWrap .visaCardImg {
  box-shadow: 0px 0px 0px 0px rgba(238, 255, 0, 0.59);
  border-radius: 6px;
}
.paymentCardWrap p {
  margin-bottom: 0px;
  margin-left: 10px;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  font-family: Proxima Nova !important;
}
.paymentCardWrap.active {
  background: var(--Black-400, #34343f);
}
.paymentCardWrap {
  background: var(--Black-500, #2b2b38);
  border-radius: 10px;
  padding: 10px 16px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.paymentCardMainWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.select-payment-method-button button {
  margin-top: 20px;
  padding: 15px 45px 15px 45px;
  border-radius: 10px;
  border: 1.4px solid var(--stroke-4, #ffffff0a);
  /* border: none; */
  box-shadow: 0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset;
  background: var(--Black-500, #2b2b38);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  width: 100%;
  cursor: pointer;
  font-family: Jost !important;
}
.QRpaymentDetailsWrap span {
  font-size: 14px !important;
  line-height: 20px !important;
  margin-bottom: 0px !important;
}
.paymentMainContentWrap {
  margin-top: 5px;
  /* padding-bottom: 50px; */
}
.paymentMainContentWrap .copyWrap {
  padding: 5px 5px 5px 15px;
  border-radius: 10px;
  background: var(--Black-800, #1d1d23);
  box-shadow: 0px 2px 8px 0px #0000001f inset;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paymentMainContentWrap .copyWrap p {
  margin-top: 0px;
  margin-bottom: 0px;
}
.paymentMainContentWrap .copyWrap img {
  height: 20px;
  width: 20px;
}
.paymentMainContentWrap .copyWrap span {
  width: 40px;
  height: 40px;
  background: radial-gradient(
    201.67% 201.67% at 50% -31.67%,
    #5d69cf 0%,
    #342a6f 100%
  );
  outline: 2.4px solid var(--Stroke-primary-btn, #6a71c7d6);
  border: none;
  box-shadow: 0px 4px 12px 2px #6c5dc13d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}
.paymentMainContentWrap .divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #707070;
  margin-top: 20px;
  margin-bottom: 20px;
}

.paymentMainContentWrap .divider::before,
.paymentMainContentWrap .divider::after {
  content: "";
  flex: 1;
  border: 1px solid var(--stroke-12, #ffffff1f);
}

.paymentMainContentWrap .divider::before {
  margin-right: 6px;
}

.paymentMainContentWrap .divider::after {
  margin-left: 6px !important;
}

.paymentMainContentWrap .divider span {
  font-size: 14px !important;
  font-weight: 700;
  color: #757786;
  line-height: 20px;
}
.QRWrap {
  text-align: center;
}
.QRWrap p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.QRWrap img {
  margin-top: 5px;
}
.secondCopyInput {
  margin-top: 20px;
}
.secondCopyInput .copyWrapSec {
  margin-top: 5px;
}
.secondCopyInput .custom-input {
  width: 100%;
  padding: 10px 5px 10px 0px;
  background-color: transparent;
  border: 1px solid transparent;
  color: #b3b3b3;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
}

.secondCopyInput .custom-input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #4d4d5b;
}

.secondCopyInput .upload-container {
  text-align: center;
  box-shadow: 0px 2px 8px 0px #0000001f inset;
  background: var(--Black-800, #1d1d23);
  border-radius: 10px;
  width: 100%;
  margin-top: 5px;
}

.secondCopyInput .file-input {
  display: none;
}

.secondCopyInput .upload-label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  padding: 22.5px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s ease;
  position: relative;
}

.secondCopyInput .file-types {
  display: block;
  font-size: 16px !important;
  color: #999;
  font-weight: 400;
  line-height: 20px !important;
  margin-top: 5px;
  /* text-align: left; */
}
.secondCopyInput .close-icon {
  position: absolute;
  top: 34px;
  right: 23px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexBtnDiv {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 3px;
}
.paymentConfirmation-Img {
  text-align: center;
}
.depositGrid .payment-confirmation {
  margin-top: -20px !important;
}
.payment-completeMainWrap::before {
  background-image: url(../public/images/topShadow.png);
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top;
}

.image-failed-class::before {
  background-image: url(../public/images/red.png);
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top;
}
.payment-information-wrap {
  text-align: center;
  margin-top: 15px;
}
.payment-information-wrap h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 0px;
}
.payment-information-wrap p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #757786;
  margin-bottom: 0px;
  margin-top: 20px;
}
.payment-information-wrap h4 {
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0px;
}
.payment-details-table {
  padding: 10px 20px 14px;
  background: #1d1d2380;
  box-shadow: 0px 2px 8px 0px #0000001f inset;
  border-radius: 10px;
  margin-top: 20px;
}
.payment-details-table h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--stroke-12, #ffffff1f);
}
.tableWrap {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.tableWrap .tableFlexDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableWrap .tableFlexDiv p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #757786;
  margin-top: 0px;
  margin-bottom: 0px;
}
.tableWrap .tableFlexDiv span {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  margin-bottom: 0px !important;
}
.tableWrap .tableFlexDiv .greenDollarImg {
  height: 23px;
  width: 23px;
  margin-right: 9px;
}
.tableWrap .tableFlexDiv .goldCoinsImg {
  height: 25px;
  width: 25px;
  margin-left: 4px;
}
.tableWrap .tableFlexDiv .spanWeight {
  font-weight: 600;
}

.payment-confirmation .flexBtnDiv button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-confirmation .flexBtnDiv button img {
  margin-right: 10px;
}
.payment-confirmation .flexBtnDiv button {
  padding: 15px 20px 15px 20px !important;
}
.flexBottomDivBonuscoin {
  gap: 15px;
  display: flex;
  flex-direction: column;
}
.card-container .discountTag {
  position: absolute;
  top: 8px;
  right: 8px;
  background: radial-gradient(
      146.43% 146.43% at 50% 10.71%,
      rgba(0, 218, 234, 0.15) 0%,
      rgba(15, 123, 218, 0.15) 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  box-shadow: 0px 2px 2px 0px #91f7ff1a inset;
  width: 32px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px !important;
  line-height: 12px !important;
  font-weight: 500;
  color: #00daea !important;
  margin-bottom: 0px !important;
  z-index: 99999 !important;
}
.select-payment-method-button .load {
  width: 20px !important;
  height: 20px !important;
  margin: 0 !important;
}
.paymentProcessMainWrap img {
  width: 100%;
}
.contentWrapPaymentProcess {
  margin-top: -90px;
}
.contentWrapPaymentProcess span {
  justify-content: center;
  font-size: 16px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  color: #fff !important;
  margin-bottom: 0px !important;
  display: block;
  text-align: center;
}
.contentWrapPaymentProcess p {
  font-size: 16px !important;
  margin-top: 24px !important;
  margin-bottom: 0px !important;
  color: #fff !important;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.contentWrapPaymentProcess a {
  color: #00daea;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-top: 8px;
  text-align: center;
  display: block;
}
.debitCardMainWrap span {
  max-width: 90%;
}
.debitCardMainWrap .debitCardInputWrap {
  margin-top: 24px;
}
.debitCardMainWrap .debitCardInputWrap .copyWrap {
  height: 32px;
}
.debitCardMainWrap .debitCardInputWrap .amountTxt {
  margin-bottom: 4px !important;
}
.footerLinkMenu {
  text-decoration: none;
  color: #666773;
  font-size: 14px;
  font-weight: 600;
}
.homepageFilter .allGameList {
  margin-top: 0px;
}
.customProfilePopup ul.MuiMenu-list li:nth-child(odd) {
  padding-left: 66px !important;
}
.customProfilePopup ul.MuiMenu-list li:last-child {
  padding-left: 0px !important;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25px);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.mobileGap {
  padding-bottom: 30px;
}
@media (max-width: 1800px) {
  .rightPanelWidth {
    width: unset !important;
  }
}
@media screen and (min-width: 1680px) {
  #account-menu-notify .MuiPaper-elevation {
    right: 72px !important;
  }
}
@media (max-width: 1650px) {
  .carousel .slide .legend {
    top: 30px;
    left: 30px;
  }
  .carousel .slide .legend h6 {
    font-size: 22px;
  }
  .carousel .slide .legend p {
    max-width: 450px;
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .carousel .slide .legend button {
    min-width: 150px;
    font-size: 16px;
  }
}
@media (max-width: 1535.95px) {
  .carousel .slide .legend {
    top: 30px;
    left: 30px;
  }
  .carousel .slide .legend h6 {
    font-size: 22px;
  }
  .carousel .slide .legend p {
    max-width: 450px;
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .carousel .slide .legend button {
    min-width: 150px;
    font-size: 16px;
    line-height: 19px;
  }
}
@media screen and (max-width: 1300px) {
  .carousel .slide .legend {
    top: 10px;
    left: 10px;
  }
  .carousel .slide .legend h6 {
    font-size: 13px;
    line-height: normal;
  }
  .carousel .slide .legend p {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .carousel .slide .legend button {
    min-width: auto;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px;
    line-height: normal;
  }
}
@media screen and (max-width: 1100px) {
  .carousel .slide .legend {
    top: 10px;
    left: 10px;
    padding: 0;
  }
  .carousel .slide .legend h6 {
    font-size: 15px;
    line-height: normal;
  }
  .carousel .slide .legend p {
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .carousel .slide .legend button {
    min-width: auto;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px;
    line-height: normal;
  }
}
@media (max-width: 959.95px) {
  #account-menu .MuiPaper-elevation {
    top: 80px !important;
  }
}

@media (max-width: 935.95px) {
  .gameBody .middle-section {
    padding: 0;
  }
  .home-icon a img {
    width: 25px;
  }
  .home-icon {
    position: absolute;
    top: 38px;
    z-index: 99;
    left: 15px;
    display: flex;
  }
  .home-icon a {
    display: block;
  }
  @supports (-webkit-touch-callout: none) {
    .orientation-mobile {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 104%;
      top: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: "12345";
    }
    .orientation-mobile img {
      width: 120px !important;
      -webkit-animation: 1s infinite alternate mover;
      animation: 1s infinite alternate mover;
    }
    .orientation-mobile.h-vertical {
      display: none;
    }
  }
}
@media screen and (max-width: 991px) {
  .modalComment {
    width: 440px !important;
  }
  .comment-popup {
    padding: 20px !important;
  }
  .transactionPageBannerSection {
    position: relative;
    background-image: url("../public/images/history-page-bg.jpg");
    background-repeat: no-repeat;
    background-position: center -226px;
  }
  #Wallet-menu {
    left: 0;
    margin-bottom: 60px;
  }
  #Wallet-menu .MuiPaper-elevation {
    width: 100%;
    overflow-y: scroll !important;
    left: 0 !important;
    margin: 0 auto;
  }
  .iframeAfterHide {
    margin-top: 58px !important;
  }
  .exit-game-link {
    font-size: 14px;
    text-decoration: none;
    background-color: #2b2b38;
    font-weight: 500;
    line-height: 17px;
    padding: 5px 11px;
    text-wrap: nowrap;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    display: block;
    border: 2px solid #ffffff0a;
    box-shadow: 0 2px 12px 0 #ffffff05 inset;
  }
  .home-icon,
  .logo-none {
    display: none !important;
  }
  .react-multi-carousel-item {
    padding-right: 0;
  }
  .leftPannel,
  .rightPannel {
    position: absolute;
    width: 100% !important;
  }
  .custom-checkbox label {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .carousel .slide img {
    height: 148px;
  }
  .middle-section {
    width: 100% !important;
    /* padding-left: 10px;
    padding-right: 10px; */
    max-width: 1200px;
  }
  .paddingBody {
    padding-left: 10px;
    padding-right: 10px;
  }
  .transaction-remove-padding .paddingBody {
    width: 100% !important;
    padding-left: 0;
    padding-right: 0;
    max-width: 1196px;
    overflow-x: hidden;
  }
  .carousel .slide .legend {
    top: 16px;
    left: 20px;
    padding: 0;
  }
  .carousel .slide .legend h6 {
    font-size: 18px;
    line-height: 24px;
  }
  .carousel .slide .legend p {
    font-size: 14px;
  }
  .carousel .slide .legend button {
    min-width: 92px;
    font-size: 14px;
    padding: 0;
    border-radius: 8px;
    font-family: Jost !important;
  }
  img[src="/images/upload-icon.svg"] {
    height: 30px !important;
    width: 30px !important;
  }
  .middle-section.SidebarClose,
  .middle-section.sidebarOpen {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 890px) {
  .transactionSelectBox {
    width: 100% !important;
    max-width: 100% !important;
  }
  .transDivFlex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 10px;
  }
}
@media (max-width: 899px) {
  .mob-view {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .comment-section {
    width: 100%;
    padding: 8px 12px;
    background: #1d1d23;
    border-radius: 8px;
    margin: 10px 0;
    overflow-x: scroll;
  }
  .comment-section p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
  }
  .pop-up-img {
    height: 64px;
    width: 64px;
    display: block;
    margin: 0px auto;
    margin-top: 30px;
  }

  .pop-up-new-styling {
    border-radius: 16px !important;
    padding: 10px 16px 16px 16px;
  }
  .close-cross {
    width: 28px !important;
    height: 28px !important;
  }

  .exit-text-btn {
    font-size: 14px !important;
    height: 32px;
  }
  .exitParaNew .exit-para-sub {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px;
  }

  .exit-btn1 {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
  }

  .exitParaNew p:nth-child(2) {
    margin-top: 16px !important;
  }

  .newMArgin p {
    margin-top: 16px;
  }
  .exit-para-sub {
    margin-bottom: 18px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .exit-para-sub-mobile-width {
    max-width: 180px;
  }

  .transactionPageBannerSection h1 {
    font-size: 18px;
    margin: 0;
    line-height: 24px;
    opacity: 1;
  }
  .custom-padding {
    padding-right: 0 !important;
    white-space: wrap;
  }
  .transactionPageBannerSection .contentSec .backBtnDiv {
    display: block;
  }
  .transactionPageBannerSection .contentSec {
    padding-bottom: 66px;
  }
  .transactionHeading {
    font-size: 24px;
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 767px) {
  .tabWrapContent .card-container {
    width: calc(50% - 4px);
    border-radius: 12px;
    padding: 12px 8px 8px 8px;
    outline: 1.08px solid rgba(201, 225, 254, 0.09);
    border: none;
  }
  .mobileGap {
    gap: 8px !important;
  }
  .tabClose .WalletHeading {
    font-size: 16px !important;
    margin-bottom: 0px !important;
    line-height: 24px !important;
  }
  .walletPopupOver .tabList {
    padding-top: 16px;
  }
  .buyNowAccoutMainWrap .buyNowCloseIcon {
    height: 28px;
    width: 28px;
    top: 0px !important;
    right: 12px;
    border-radius: 8px !important;
  }
  .tabHeadingNew button {
    min-height: 40px !important;
    font-size: 16px !important;
  }
  .transactionPageBannerSection .contentSec {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .transactionHeading {
    font-size: 12px;
    margin-top: 12px;
  }
  .transactionPageBannerSection .contentSec .backBtnDiv {
    top: 8px;
    left: 8px;
  }
  .custom-checkbox .container .checkmark:after {
    left: 4px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .custom-checkbox .container input:checked ~ .checkmark {
    border-radius: 4px;
  }
  .backBtn {
    width: 65px !important;
    gap: 4px;
  }
  .transactionSelectBox .select__control {
    height: 32px !important;
    min-height: 32px !important;
  }
  .transactionPaymentType {
    margin-top: 16px !important;
  }
  .transactionThemeCard {
    border-radius: 16px;
    margin: 0 0 24px;
  }
  .transDivFlex {
    gap: 0;
  }
  .tabTransitionCustom .Tabsection {
    min-height: 32px !important;
  }
  .transactionTableDesk {
    display: none;
  }
  .tabTransitionCustom .MuiTabs-scroller {
    border-radius: 8px !important;
  }
  .transaction-scroller {
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch !important;
    width: 100%;
  }
  .mobileTableMainWrap {
    display: flex;
    margin-top: 16px;
  }
  .footerLinkMenu {
    text-decoration: none;
    color: #666773;
    font-size: 12px;
    font-weight: 600;
  }

  .leftArrowIcon {
    width: 26px;
    height: 26px;
    min-width: 26px !important;
    border-radius: 6px !important;
    padding: 0 !important;
    background: #2b2b38 !important;
    box-shadow: inset 0px 2px 12px rgba(255, 255, 255, 0.02);
  }
  .rightArrowIcon {
    width: 26px;
    height: 26px;
    min-width: 26px !important;
    border-radius: 6px !important;
    padding: 0 !important;
    background: #2b2b38 !important;
    box-shadow: inset 0px 2px 12px rgba(255, 255, 255, 0.02);
  }

  .rightArrowIcon svg {
    fill: #666773;
  }
  .leftArrowIcon svg {
    fill: #666773;
  }

  .betTableMainWrap .transaction-head,
  .bonusHistoryTableWrap .transaction-head,
  .theadMainWrap .transaction-head {
    display: flex;
    align-items: center;
    height: 40px;
    gap: 16px;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: 8px;
  }
  .betTableMainWrap .transaction-head .transaction-head-column,
  .theadMainWrap .transaction-head .transaction-head-column {
    padding: 8px 10px 8px 8px;
  }
  .betTableMainWrap .transaction-head span,
  .bonusHistoryTableWrap .transaction-head span,
  .theadMainWrap .transaction-head span {
    font-size: 12px;
    font-weight: 500;
    color: #757786;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:first-child,
  .betTableMainWrap .transaction-head .transaction-head-column:first-child,
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:first-child,
  .bonusHistoryTableWrap .transaction-head .transaction-head-column:first-child,
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:first-child,
  .theadMainWrap .transaction-head .transaction-head-column:first-child {
    min-width: 30px;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(2),
  .betTableMainWrap .transaction-head .transaction-head-column:nth-child(2),
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(2),
  .bonusHistoryTableWrap
    .transaction-head
    .transaction-head-column:nth-child(2),
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(2),
  .theadMainWrap .transaction-head .transaction-head-column:nth-child(2) {
    min-width: 92px;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(9),
  .betTableMainWrap .transaction-head .transaction-head-column:nth-child(9),
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(8),
  .bonusHistoryTableWrap
    .transaction-head
    .transaction-head-column:nth-child(8),
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(3),
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(8),
  .theadMainWrap .transaction-head .transaction-head-column:nth-child(3),
  .theadMainWrap .transaction-head .transaction-head-column:nth-child(8) {
    min-width: 87px;
  }
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(4),
  .theadMainWrap .transaction-head .transaction-head-column:nth-child(4) {
    min-width: 104px;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(8),
  .betTableMainWrap .transaction-head .transaction-head-column:nth-child(8),
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(7),
  .bonusHistoryTableWrap
    .transaction-head
    .transaction-head-column:nth-child(7),
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(5),
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(7),
  .theadMainWrap .transaction-head .transaction-head-column:nth-child(5),
  .theadMainWrap .transaction-head .transaction-head-column:nth-child(7) {
    min-width: 96px;
  }
  .cardWrapScreen {
    position: fixed;
    width: 90%;
    bottom: 0px;
    z-index: 99999;
    padding-bottom: 16px;
    background: linear-gradient(
      2.22deg,
      #1d1d23 56.56%,
      rgba(29, 29, 35, 0.394796) 75.46%,
      rgba(29, 29, 35, 0) 91.81%
    );
    padding-top: 16px;
  }
  .paymentCardWrap .cashappimg {
    border-radius: 0px;
  }
  .paddingBottom50 {
    padding-bottom: 60px !important;
  }
  .tabClose .flexDiv {
    display: flex !important;
    align-items: center !important;
    background: #202027;
    padding-bottom: 16px;
    position: relative;
    z-index: 99;
  }
  .successPage.flexDiv {
    background: transparent;
  }

  .secondCopyInput .uplodedFile {
    text-align: start;
  }
  .secondCopyInput .uplodedFile .upload-label span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px !important;
  }
  .secondCopyInput .uplodedFile .upload-label .file-types {
    font-weight: 400 !important;
  }
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(6),
  .bonusHistoryTableWrap
    .transaction-head
    .transaction-head-column:nth-child(6),
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(6),
  .theadMainWrap .transaction-head .transaction-head-column:nth-child(6) {
    min-width: 73px;
  }
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(10),
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(9),
  .theadMainWrap .transaction-head .transaction-head-column:nth-child(10),
  .theadMainWrap .transaction-head .transaction-head-column:nth-child(9) {
    min-width: 86px;
  }
  .betTableMainWrap .transaction-body .transaction-body-row,
  .bonusHistoryTableWrap .transaction-body .transaction-body-row,
  .theadMainWrap .transaction-body .transaction-body-row {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 8px;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(2)
    span,
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(2)
    span,
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(2)
    span {
    display: block;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(4)
    span,
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(5)
    span,
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(7)
    span,
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(8)
    span,
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(4)
    span,
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(5)
    span,
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(7)
    span,
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(8)
    span,
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(4)
    span,
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(5)
    span,
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(7)
    span,
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(8)
    span {
    display: flex;
    align-items: center;
  }
  .betTableMainWrap .transaction-body span,
  .bonusHistoryTableWrap .transaction-body span,
  .theadMainWrap .transaction-body span {
    font-size: 12px;
    font-weight: 500;
    color: #757786;
    display: flex;
  }
  .MuiPaper-elevation {
    border-radius: 0px !important;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column,
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column,
  .theadMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column {
    padding: 6px 8px;
  }
  .betTableMainWrap .transaction-body:nth-child(2n),
  .bonusHistoryTableWrap .transaction-body:nth-child(2n),
  .theadMainWrap .transaction-body:nth-child(2n) {
    background-color: #282831;
  }
  .mobileWrap {
    border-radius: 0px !important;
    /* height: 100vh; */
  }
  /* .mainWrapRedeemTab {
    height: calc(100vh - 100px);
  } */
  .betFlexDiv span {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    line-height: 15px;
    overflow: hidden;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(3),
  .betTableMainWrap .transaction-head .transaction-head-column:nth-child(3) {
    min-width: 93px;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(4),
  .betTableMainWrap .transaction-head .transaction-head-column:nth-child(4) {
    min-width: 68px;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(5),
  .betTableMainWrap .transaction-head .transaction-head-column:nth-child(5) {
    min-width: 56px;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(6),
  .betTableMainWrap .transaction-head .transaction-head-column:nth-child(6) {
    min-width: 100px;
  }
  .betTableMainWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(7),
  .betTableMainWrap .transaction-head .transaction-head-column:nth-child(7) {
    min-width: 100px;
  }
  .bonusHistoryTableWrap .transaction-head .transaction-head-column {
    padding: 8px;
  }
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(3),
  .bonusHistoryTableWrap
    .transaction-head
    .transaction-head-column:nth-child(3) {
    min-width: 91px;
  }
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(4),
  .bonusHistoryTableWrap
    .transaction-head
    .transaction-head-column:nth-child(4) {
    min-width: 67px;
  }
  .bonusHistoryTableWrap
    .transaction-body
    .transaction-body-row
    .transaction-body-column:nth-child(5),
  .bonusHistoryTableWrap
    .transaction-head
    .transaction-head-column:nth-child(5) {
    min-width: 78px;
  }
  .RoutesWraper {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .match-card-content .MuiGrid-item {
    padding: 0;
  }
  .TransactionExtoPopup,
  .comment-width {
    max-width: 100%;
    min-width: 100%;
  }
  .QRpaymentDetailsWrap {
    margin-top: 16px !important;
  }
  #account-menu-notify .MuiPaper-elevation {
    top: 70px !important;
    max-width: calc(100% - 20px);
    right: 10px !important;
  }
  .banner_image img {
    height: 100% !important;
  }

  .custom-checkbox label {
    font-size: 12px;
  }
  .custom-checkbox label {
    font-size: 12px;
  }
  .custom-checkbox .checkmark {
    height: 16px;
    width: 16px;
    border-radius: 4px;
  }

  .container {
    padding-left: 23px;
  }
  .buy-coins-dialog {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .buy-coins-dialog .MuiDialog-pape {
    max-height: calc(100% - 0px) !important;
  }
  .buy-coins-dialog .MuiDialog-container {
    height: auto !important;
  }
  .buyNowAccoutMainWrap {
    padding: 16px 16px 20px !important;
    border-radius: 0px !important;
    /* min-height: 100vh;
    max-height: 100vh; */
    height: calc(100vh - 60px);
    position: fixed;
    bottom: 0;
    top: 0;
    z-index: 99999999 !important;
    /* overflow: hidden !important; */
  }
  .mobileProgresBar {
    margin-top: 40px !important;
  }
  /* .buynowCardWrap span{
    display: none !important;
  } */
  .mobileHide {
    display: none !important;
  }

  .payments-scroll {
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding-right: 7px;
    width: calc(100% + 7px) !important;
  }

  .depositGrid::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .tabWrapContent .icon {
    width: 54px;
    height: 46px;
  }
  .bitCoinPaymentBtnWrap {
    position: fixed;
    bottom: 16px;
    width: 90%;
  }
  .tabWrapContent .card-body h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .depositGrid .payment-confirmation {
    margin-top: 0px !important;
  }

  .tabWrapContent .card-body {
    margin-top: 48px;
  }
  .tabWrapContent .card-body p {
    font-size: 12px;
    line-height: 12px;
    margin: 4px 0 8px;
  }
  .tabWrapContent .card-header {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .tabWrapContent .price-button {
    padding: 5px 7px 5px 7px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 6px;
  }

  .tabWrapContent .old-price {
    text-decoration: line-through;
    color: #757786;
    font-size: 11px !important;
    margin-bottom: 0px !important;
    font-weight: 600;
  }

  .tabWrapContent .card-container::before {
    background-position-x: center;
    right: 0;
  }
  .tabWrapContent .card-container::after {
    background-position-x: center;
    right: 0;
    height: 67px !important;
  }
  .card-container.active::before {
    background-position-x: left !important;
  }
  /* .card-container.active::after {
    left: 8px !important;
    top: 0px;
    background-position: right;
  } */
  .tabHeadingNew button {
    border-radius: 8px !important;
  }
  .buyNowBtnWrap button {
    /* padding: 11px 30px 11px 30px; */

    padding: 10px 30px 11px 30px;
    line-height: 18px;
    border-radius: 8px !important;
    margin-top: 0px;
  }
  .buy-coins-dialog .depositGrid {
    /* min-height: 85vh !important; */
    height: calc(100vh - 20px);
    /* padding-bottom: 60px; */
  }
  .buynowCardWrap span {
    line-height: 16px;
    margin-bottom: 8px !important;
  }
  .buynowCardWrap .mobileCopyImg {
    margin-bottom: 0px !important;
  }
  .select-payment-methodWrap {
    margin-top: 0px;
  }
  .paymentCardWrap p {
    line-height: 16px;
    margin-left: 8px;
  }
  .secondCopyInput .close-icon {
    position: absolute;
    top: 34px;
    right: 23px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .secondCopyInput .progress-bar {
    top: 16px;
    right: 14px;
  }

  .paymentCardWrap {
    padding: 8px 8px 8px;
    border-radius: 8px;
  }
  .paymentCardWrap img {
    height: 24px;
    width: 24px;
    box-shadow: none !important;
  }
  .paymentCardMainWrap {
    gap: 8px;
  }
  .select-btn-mobile {
    position: fixed;
    bottom: 16px;
    width: 90%;
  }

  .select-payment-method-button button {
    /* padding: 11px 45px 11px 45px; */
    padding: 11px 45px 10px 45px;
    line-height: 18px;
    border-radius: 8px;
    margin-top: 0px;
  }
  .paymentMainContentWrap {
    margin-top: -4px;
    padding-bottom: 0px !important;
  }
  .paymentMainContentWrap .copyWrap p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 88%;
  }
  .paymentMainContentWrap .copyWrap {
    padding: 2px 5px 2px 12px;
    border-radius: 8px;
  }
  .paymentMainContentWrap .copyWrap span {
    height: 28px;
    width: 28px;
    border-radius: 8px;
  }
  .paymentMainContentWrap .copyWrap img {
    height: 16px;
    width: 16px;
  }
  .paymentMainContentWrap .debitWrap {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .paymentMainContentWrap .divider .paymentMainContentWrap .divider span {
    line-height: 18px !important;
    margin-bottom: 0px !important;
  }
  .debitCardMainWrap .redirecTxt p {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #fff;
    margin-bottom: 8px;
    margin-top: 0px;
  }
  .QRWrap img {
    height: 120px;
    width: 120px;
    border-radius: 14px;
  }
  .secondCopyInput .custom-input {
    padding: 3.1px 4px 3.1px 0px;
    line-height: 20px !important;
  }
  .secondCopyInput {
    margin-top: 16px;
  }
  .secondCopyInput .copyWrapSec {
    margin-top: -4px;
    border-radius: 8px;
  }
  .secondCopyInput .upload-container {
    margin-top: -4px;
    border-radius: 8px;
    margin-bottom: 6px;
    position: relative;
  }
  .secondCopyInput .upload-label {
    /* padding: 10px 20px; */
    padding: 8px 12px 8px 12px;
  }
  .secondCopyInput .file-types {
    margin-top: 0px;
    margin-bottom: 0px !important;
    color: #4d4d5b !important;
  }
  /* .buyNowBtnWrap button {
    margin-top: 16px;
  } */
  .FirestScreenBtn button {
    position: fixed;
    width: 90%;
    bottom: 16px;
    border: 0.7px solid var(--Stroke-primary-btn, #6a71c7d6) !important;
  }
  /* .select-payment-method-button button {
    margin-top: 16px;
  } */
  .flexBtnDiv {
    gap: 8px;
    position: fixed;
    bottom: 0;
    width: 90%;
    background: #202027;
    padding-bottom: 16px;
  }
  .paymentConfirmation-Img img {
    height: 79px;
    width: 79px;
    margin-top: 24px;
  }
  .payment-completeMainWrap::before {
    background-position-x: center;
    right: 0;
  }
  .payment-information-wrap h3 {
    display: none;
  }
  .payment-information-wrap {
    margin-top: -10px;
  }
  .payment-information-wrap p {
    font-size: 14px;
    line-height: 16px;
  }
  .payment-information-wrap h4 {
    font-size: 18px;
    font-weight: 800;
    line-height: 16px;
    color: #fff;
    margin-top: 8px;
  }
  .payment-details-table {
    margin-top: 16px;
  }
  .payment-details-table {
    padding: 8px 16px 16px;
    border-radius: 8px;
  }
  .payment-details-table h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 8px;
  }
  .tableWrap .tableFlexDiv {
    align-items: flex-start;
    flex-direction: column;
  }
  .tableWrap {
    margin-top: 12px;
    gap: 12px;
  }
  .payment-confirmation .flexBtnDiv button {
    padding: 11px 3px 11px 3px !important;
    line-height: 18px !important;
  }
  .payment-confirmation .flexBtnDiv button img {
    margin-right: 4px;
    height: 16px;
    width: 16px;
  }
  .flexBottomDivBonuscoin {
    gap: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
    /* justify-content: space-between; */
  }
  /* .buyNowAccoutMainWrap .payment-confirmation{
    background-color: red !important;
  } */
  .flexBottomDivBonuscoin .marginLeft64 {
    margin-left: 64px;
  }
  .QRpaymentDetailsWrap .bitCoinQRWrap {
    margin-top: 24px;
  }
  .QRpaymentDetailsWrap .bitCoinQRWrap .qr-inside-wrap {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    gap: 16px;
  }
  .QRpaymentDetailsWrap .bitCoinQRWrap .qr-inside-wrap img {
    border-radius: 14px;
  }
  .QRpaymentDetailsWrap .bitCoinQRWrap .qr-inside-wrap .contentWrap p {
    color: var(--Black-200, rgba(117, 119, 134, 1));
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 0px !important;
  }
  .QRpaymentDetailsWrap .bitCoinQRWrap .qr-inside-wrap .contentWrap h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: #fff;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .QRpaymentDetailsWrap
    .bitCoinQRWrap
    .qr-inside-wrap
    .contentWrap
    .marginbottom8 {
    margin-top: 8px;
  }
  .QRpaymentDetailsWrap .marginTop24 {
    margin-top: 24px !important;
  }

  .QRpaymentDetailsWrap
    .bitCoinQRWrap
    .qr-inside-wrap
    .contentWrap
    .LoaderWrap {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
  .QRpaymentDetailsWrap
    .bitCoinQRWrap
    .qr-inside-wrap
    .contentWrap
    .LoaderWrap
    p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 800;
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgba(0, 218, 234, 1);
    margin-left: 11px;
  }
  .QRpaymentDetailsWrap .bitCoinQRWrap .qr-inside-wrap .contentWrap .loaderNew {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }

  .QRpaymentDetailsWrap
    .bitCoinQRWrap
    .qr-inside-wrap
    .contentWrap
    .loaderNew::after {
    content: "";
    width: 24px;
    height: 24px;
    border: 4px solid rgba(0, 218, 234, 0.488);
    border-top-color: rgba(0, 218, 234, 1);
    border-radius: 50%;
    animation: loading 0.75s ease-out infinite;
  }
  .buyNowBtnWrap button span {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #fff;
    cursor: pointer;
    font-family: Jost !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px !important;
    margin-right: 5px;
  }
  .paymentProcessMainWrap img {
    width: 100%;
  }
  .contentWrapPaymentProcess {
    margin-top: -90px;
  }
  .contentWrapPaymentProcess span {
    justify-content: center;
    font-size: 16px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    color: #fff !important;
    margin-bottom: 0px !important;
  }
  .contentWrapPaymentProcess p {
    font-size: 16px !important;
    margin-top: 24px !important;
    margin-bottom: 0px !important;
    color: #fff !important;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }
  /* .mobileGap {
    padding-bottom: 0px !important;
  } */
  .mobileGap .LoaderWrap {
    height: 50vh;
  }
  .mobileGap .LoaderWrap .load {
    position: absolute;
    top: 50%;
    transform: translateY(50%);
  }
  .RedeemLoaderWrap .LoaderWrap {
    height: 50vh;
  }
  .RedeemLoaderWrap .LoaderWrap .load {
    position: absolute;
    top: 50%;
    transform: translateY(50%);
  }
  .secondCopyInput .custom-input::placeholder {
    font-size: 16px !important;
    font-family: Jost !important;
  }
  .gamelistingLoaderMainWrap{
    width: 100%;
  }
}
@media screen and (max-width: 940px) and (orientation: landscape) {
  .gameBody .fixedHeader {
    transform: rotate(-90deg);
    position: fixed !important;
    transform-origin: left;
    top: 89% !important;
    left: 45px;
    width: -moz-fit-content !important;
    width: fit-content !important;
    max-width: 100% !important;
    z-index: 99999 !important;
    min-height: 90px;
  }
  .gameBody .fixedHeader::after {
    content: "";
    height: 60px;
    width: 202px;
    background-color: #24242b;
    display: block;
    position: absolute;
    right: -194px;
    top: -1px;
  }
  .gameBody .makeStyles-dialogBox-1085 .MuiDialog-paper {
    max-height: calc(100% - 22px);
  }
  .gameBody .balanceBtnMobile {
    width: 158px !important;
    margin-right: -65px !important;
  }
  .gameBody .balanceBtnMobile span {
    padding-left: 2px !important;
  }
  .gameBody .arrowiconLandscape {
    margin-left: -23px !important;
    margin-right: 10px !important;
  }
  .gameBody .userNotifyLandscape {
    margin-right: -100px !important;
  }
  .gameBody .portrateLogo img {
    display: none;
  }
  .gameBody .mobilePortrateLogo {
    display: block !important;
  }
  .gameBody .mobilePortrateLogo img {
    width: 40px !important;
    margin-right: 12px;
  }
  .gameBody .mobileLandscapeIcon {
    left: -5px !important;
    top: -2px !important;
  }
  .gameBody .headerAftheLoginlandscape {
    gap: 74px !important;
  }
  .gameBody .landscapeHeaderUserName {
    margin-right: -36px !important;
  }
  .gameBody .headerProgressBarLandscape,
  .gameBody .landscapeHeaderUserName p {
    display: none !important;
  }
  .gameBody .arrowiconLandscape {
    left: -28px !important;
  }
  .gameBody .userIconWallet {
    width: 48px !important;
    height: 48px !important;
    margin-left: 0 !important;
  }
  .gameBody .userIconWallet img {
    width: 43px !important;
    height: 43px !important;
    max-width: 43px !important;
    max-height: 43px !important;
  }
  .gameBody .marginToplobby {
    margin-top: 20px !important;
  }
  .gameBody .iframeAfterHide {
    margin-top: 0 !important;
    height: 100%;
    width: 100%;
    padding-left: 72px;
  }
  .gameBody .iframeAfterHide iframe {
    /* height: 100vh !important; */
    border-radius: 0;
  }
  .gameBody .exit-game-link {
    margin-right: 10px !important;
  }
  .gameBody .dialogBoxLandscape .MuiDialog-paper {
    transform: rotate(270deg);
  }
  .portrateLogo img {
    width: 50px !important;
  }
  .select__control {
    max-width: 100% !important;
    margin-top: 12px;
  }
  .landscapeSearch {
    width: 100% !important;
    margin-top: 12px;
  }
  .seeAllBtnLandscape {
    height: 26px !important;
    min-width: 0px !important;
  }
  .footerLinkMenu {
    font-size: 14px !important;
  }
  .loginSocialBtnWrap {
    width: 100%;
    position: relative !important;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .loginMainWrap {
    height: 87vh;
    min-width: 440px;
  }
  .masterMainWrap {
    min-width: 406px;
  }
  .forgotPasswordMainWrap {
    height: 87vh !important;
    border-radius: 8px;
  }
  .enterMailMainWrap .bottomBtnFixedWrap {
    width: 93%;
  }
  .landscapePopup .MuiPaper-elevation {
    background-color: #202027 !important;
  }
  /* .tabClose .flexDiv{
    margin-top: 30px;
  } */
  .buyNowAccoutMainWrap {
    position: relative !important;
  }
  .walletPopupOver .tabList {
    padding-top: 0px !important;
  }
  .cardWrapScreen {
    position: relative !important;
    width: 100% !important;
  }
  .select-btn-mobile {
    position: relative !important;
    width: 100% !important;
    bottom: -26px !important;
  }
  .buyNowAccoutMainWrap {
    outline: none !important;
  }
  .banner_image img {
    height: 300px !important;
  }
  #account-menu .MuiPaper-elevation {
    top: 11px !important;
  }
  .buyTabWrap {
    margin-top: 0px;
  }
  .gameBody #launcher{
    display: none !important;
  }
  .iframeAfterHide .mbIframe {
    height: 100%;
    width: calc(100vw - 90px);
}

  /* .tabClose .flexDiv{
    margin-top: 38px;
  } */
  /* .leftArrowIcon{
    height: 32px !important;
    width: 32px !important;
  } */
}
.contentWrapPaymentProcess a {
  color: #00daea;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-top: 8px;
  text-align: center;
  display: block;
}
@keyframes loading {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
.cardWrapScreen button {
  margin-top: 0px !important;
}
.depositGrid .buynowCardWrap {
  padding-top: 0px !important;
}
/* .tableFlexDiv .bottom13{
    margin-bottom: 13px !important;
  } */

.cash-app-uploaded-file-name {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #cccccca3;
}

.cash-app-uploaded-file-name p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  display: inline-block;
  text-overflow: ellipsis;
  width: 60%;
  /* padding-right: 150px; */
  margin-top: 0px;
  line-height: 24px !important;
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .choose-game-btn,
  .choose-game-red-btn {
    font-size: 14px !important;
    padding: 8px 20px !important;
  }
  .second-popup {
    width: 100% !important;
    min-width: 300px !important;
    transform: translate(0, 128%);
    margin: 0 auto;
  }
  .close-icon-shift {
    margin-left: 18px;
    margin-top: -3px;
  }
  .second-popup .card-img {
    width: 36.5%;
  }
  .second-popup .card-img img {
    min-height: 136px;
  }
  .p-0 {
    padding-left: 12px !important;
    width: 63.5%;
  }
  .sec-exit-para p:first-child {
    font-size: 16px;
    line-height: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sec-exit-para p:nth-child(2) {
    font-size: 14px;
  }
  .exit-margin-bt {
    background-color: transparent !important;
  }
  .cross-absolute {
    right: 0 !important;
  }
  .walletPopupOver .tabClose {
    position: fixed !important;
    width: calc(100% - 20px);
  }
  .walletPopupOver .tabList {
    padding-top: 35px;
    margin-bottom: 16px;
  }
  .walletPopupOver .depositGrid {
    /* max-height: 85vh; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40px;
    height: calc(100vh - 100px);
    /* position: relative;
    scrollbar-width: thin;  */
    /* scrollbar-color: #5a60ff #2d2d3e; */
  }
  .smallScreen {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .homePageLoaderContent {
    height: calc(100vh - 665px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .searchGamePageWrap {
    height: calc(100vh - 665px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .paddingBody{
    height: calc(100vh - 350px);
  } */
  /* .walletPopupOver .depositGrid::-webkit-scrollbar {
  width: 2px;
  display: block !important;
  margin-top: 20px !important;
}
.walletPopupOver .depositGrid::-webkit-scrollbar-thumb {
background-color: #2B2B38;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); 
margin-top: 16px; 
margin-left: 7px;  
margin-right: 2px; 
}

.walletPopupOver .depositGrid::-webkit-scrollbar-track {
  background-color: #17171B;
  border-radius: 10px;
} */
  .home-mob-slider .react-multi-carousel-item {
    padding-right: 0;
    padding-left: 0;
  }
  .mbiframeMobile {
    border-radius: 0 !important;
  }
  #coin-menu .MuiPaper-elevation {
    top: 75px !important;
    right: 50%;
    transform: translateX(50%) !important;
  }
  .select__control {
    max-width: 120px;
    min-width: 92px;
    border-radius: 8px;
    height: 33px;
    min-height: 33px;
    background: #2b2b38;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12) inset;
    border: 1.4px solid;
  }
  .select__dropdown-indicator {
    padding: 5px 12px 5px 5px;
  }
  .select__dropdown-indicator svg {
    width: 16px;
  }
  .select__placeholder {
    color: #fff;
    font-size: 16px;
  }
  .select__single-value {
    text-overflow: ellipsis;
    font-size: 16px;
    color: #fff !important;
    font-weight: 500;
  }
  .reactSeletContent .select__control {
    max-width: 100% !important;
    min-width: 100% !important;
    height: 48px;
    border-radius: 8px;
    border: 1.4px solid rgba(255, 255, 255, 0.04);
    background: #2b2b38;
    box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.02) inset;
  }
  .bonusSectionContent .react-multi-carousel-item,
  .carousel-with-spacing .react-multi-carousel-item:nth-child(2n),
  .carousel-with-spacing .react-multi-carousel-item:nth-child(odd) {
    padding: 0;
  }
  .reactSeletContent .select__value-container {
    padding: 0 20px;
  }
  .reactSeletContent .select__indicator {
    padding-right: 20px;
  }
  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    min-height: inherit;
    min-width: inherit;
  }
  .filterSectionSort .select__single-value {
    font-size: 14px !important;
  }
  .tabWrapContent .select__control {
    height: 38px !important;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 7px;
  }
  .walletNumber {
    margin-left: 6px;
    font-size: 15px;
    font-weight: 500;
  }
  .bonusSvg svg {
    height: 49px !important;
    width: 49px !important;
  }
  .loaderCenter {
    text-align: center;
    margin: 0 auto;
  }
  .searchLoaderWrap {
    margin: 0 auto;
    width: 100%;
    /* padding-top: 20px;
    padding-bottom: 20px; */
  }
  .gameBody #launcher{
    display: none !important;
  }
}
@media screen and (max-width: 500px) {
  .transDivFlex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column-reverse;
    padding: 0 12px;
  }

  .betTableSpanWidth {
    width: 108px;
  }
  @media screen and (max-width: 480px) {
    .phone_inp1 {
      width: 15% !important;
    }
    .phone_inp2 {
      width: 83% !important;
    }
    .modalComment {
      width: 100% !important;
    }
    .carousel-with-spacing .react-multi-carousel-item {
      margin-top: 10px !important;
    }
    .home-icon {
      display: none !important;
    }
    .custom-padding {
      padding: 0 !important;
    }
    .mobileTableIcon {
      height: 16px !important;
      width: 16px !important;
    }
  }
  @media (max-width: 475.95px) {
    .orientation-mobile.h-vertical {
      display: none;
    }
    .second-popup {
      padding: 12px;
    }
    .sec-exit-para p:nth-child(2) {
      margin-top: 5px;
      line-height: 20px;
    }
    .choose-game-btn {
      margin-top: 12px !important;
      margin-bottom: 10px !important;
    }
    .customProfilePopup ul.MuiMenu-list li:nth-child(odd) {
      padding-left: 72px !important;
    }
    .customProfilePopup ul.MuiMenu-list li:last-child {
      padding: 0px !important;
    }
    
  }
  @media screen and (max-width: 370px) {
    .phone_inp1 {
      width: 18% !important;
    }
    .phone_inp2 {
      width: 80% !important;
    }
  }
}
@media (max-width: 475.95px) {
  .iframeAfterHide .mbIframe{
    position: fixed;
      top: 58px;
      right: 0;
      left: 0;
      bottom: 0;
      height: calc(100% - 58px)
  }
}
@media screen and (max-width: 322px) {
  .balanceBtnMobile {
    margin-left: 12px !important;
  }
  .balanceBtnMobile {
    width: 148px !important;
    margin-right: 5px !important;
  }
  /* .customProfilePopup .MuiPaper-elevation8 .MuiMenu-list:last-child{
    padding: 12px !important;
  } */
  .customProfilePopup ul.MuiMenu-list li:nth-child(odd) {
    padding-left: 47px !important;
  }

  .customProfilePopup ul.MuiMenu-list li:last-child {
    padding: 0px !important;
  }
}
/* @media screen and (min-width:390px) and (max-width:440px){
  .buyNowAccoutMainWrap{
    height: calc(100vh - 100px);
  }
} */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  /* Styles for iPhone 12, 13, 14 */
  .buyNowAccoutMainWrap {
    height: calc(100vh - 100px) !important;
  }
  .paddingBottom50 {
    padding-bottom: 140px !important;
  }
}
@media screen and (max-width: 322px) {
  .balanceBtnMobile {
    margin-right: 10px;
    margin-left: 27px;
  }
  .userIconWallet {
    margin-left: -11px !important;
  }
  .arrowIcon {
    margin-left: 8px !important;
    margin-right: 10px !important;
  }
  .close-icon-shift {
    margin-left: 11px;
  }
  .exit-game-link {
    font-size: 12px;
    text-decoration: none;
    background-color: #2b2b38;
    font-weight: 500;
    line-height: 17px;
    padding: 3px 12px;
    text-wrap: nowrap;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    display: block;
    border: 2px solid #ffffff0a;
    box-shadow: 0 2px 12px 0 #ffffff05 inset;
  }
  .footerLinkMenu {
    font-size: 9px;
  }
  /* .buyNowAccoutMainWrap {
    height: calc(100vh - 0px);
  } */
}
@media screen and (orientation: landscape) {
  .home-icon {
    left: 36px;
  }
}
